import React, { useRef, useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import posiciom from "./../../images/marcadorPosicion.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
`;

// Cambiar ContactForm a form en lugar de div
const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: rgba(17, 25, 40, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 32px;
  border-radius: 12px;
  box-shadow: rgba(23, 92, 230, 0.1) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`;

const ContactTitle = styled.div`
  font-size: 28px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`;

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary + 50};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary + 50};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactButton = styled.input`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: #f3ab1e;
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
`;

const Contact = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false); // Estado para mostrar el mensaje de éxito

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = form.current.from_email.value;
    const name = form.current.from_name.value;
    const subject = form.current.subject.value;
    const message = form.current.message.value;

    if (!email || !name || !subject || !message) {
      alert("Por favor, completa todos los campos.");
      return;
    }

    emailjs
      .sendForm(
        "service_ms7hsif",
        "template_t80bje2",
        form.current,
        "7H3CpZJ5235_gx9iz"
      )
      .then(
        (result) => {
          setIsSubmitted(true); // Cambiar el estado cuando el formulario se envía
          form.current.reset(); // Resetear el formulario después de enviar
        },
        (error) => {
          alert("Error al enviar el mensaje, inténtalo de nuevo.");
          console.log(error);
        }
      );
  };

  return (
    <Container>
      <ContactForm onSubmit={handleSubmit} ref={form}>
        <ContactTitle>Contáctanos por email 🚀</ContactTitle>
        <ContactInput placeholder="Tu correo electrónico" name="from_email" />
        <ContactInput placeholder="Su nombre" name="from_name" />
        <ContactInput placeholder="Asunto" name="subject" />
        <ContactInputMessage placeholder="Mensaje" name="message" rows={4} />
        <ContactButton type="submit" value="Enviar" />
      </ContactForm>
      {isSubmitted && (
        <p style={{ color: "green" }}>Mensaje enviado con éxito!</p>
      )}
    </Container>
  );
};

export default Contact;
