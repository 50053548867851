import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import imagenPubli from "./images/publicidad.jpg";
import Servicios from "./components/sections/Servicios";
import Cocina from "./components/sections/Cocina";
import Diversion from "./components/sections/Diversion";
import Navbar from "./components/Navbar";
import Experience from "./components/sections/Experience";
import Hero from "./components/sections/Hero";
import Contact from "./components/sections/Contact";
import Footer from "./components/sections/Footer";
import StartCanvas from "./components/canvas/Stars";
import styled, { ThemeProvider } from "styled-components";
import { darkTheme } from "./utils/Themes";
import PhotoCollage3D from "./components/sections/albunes/PhotoCollage3D";
import PhotoCollage3DDiversion from "./components/sections/albunes/PhotoCollage3DDiversion";
import PhotoCollage3DCocina from "./components/sections/albunes/PhotoCollage3DCocina";
import ScrollToTop from "./components/ScrollToTop";
import Aliados from "./components/sections/Aliados";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
  position: relative;
`;

const Wrapper = styled.div`
  padding-bottom: 100px;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

// Modal de Publicidad
/** Modal de Publicidad
const ModalPublicidad = ({ isVisible, onClose }) => {
  const phoneNumber = "+593997924630";
  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        padding: "20px", // Para agregar padding en móviles
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          textAlign: "center",
          maxWidth: "500px", // Limita el ancho del modal en pantallas grandes
          width: "100%", // Para que el modal sea responsivo
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)", // Sombra para darle un efecto elegante
        }}
      >
        // Imagen de la publicidad //
        <img
          src={imagenPubli}
          alt="Publicidad"
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "10px",
          }}
        />
        <h2
          style={{
            fontSize: "1.5rem",
            marginTop: "15px",
            color: "#333",
          }}
        >
          ¡Bienvenidos a Casa D´Bruno!
        </h2>
        <p style={{ fontSize: "1rem", color: "#555" }}>
          Esta es nuestra publicidad especial
        </p>
        // Enlace a WhatsApp //
        <a
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
            "Hola, Estoy interesado en obtener más información de la publicidad de la web. ¿Podrías proporcionarme más información?"
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#25D366", // Color de WhatsApp
            border: "none",
            color: "#fff",
            cursor: "pointer",
            borderRadius: "5px",
            margin: '4px',
            fontSize: "1rem",
            textDecoration: "none", // Para quitar el subrayado del enlace
          }}
        >
          Reservar por WhatsApp
        </a>

        // Botón para cerrar el modal //
        <button
          onClick={onClose}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#e2241d",
            border: "none",
            color: "#fff",
            cursor: "pointer",
            borderRadius: "5px",
            fontSize: "1rem",
          }}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};
**/

const AppLayout = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Mostrar el modal solo al inicio
    if (location.pathname === "/") {
      setShowModal(true);
    }
  }, [location.pathname]);

  const closeModal = () => setShowModal(false);

  const showFooter = !(
    location.pathname === "/collage" ||
    location.pathname === "/collageDiversion" ||
    location.pathname === "/collageCocina"
  );

  return (
    <>
      <Navbar />
      <Body>
        <StartCanvas />
        {/*<ModalPublicidad isVisible={showModal} onClose={closeModal} />*/}
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Hero />
                <Wrapper>
                  <Experience />
                </Wrapper>
                <Wrapper>
                  <Contact />
                </Wrapper>
              </div>
            }
          />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/cocina" element={<Cocina />} />
          <Route path="/diversion" element={<Diversion />} />
          <Route path="/aliados" element={<Aliados />} />
          <Route path="/collage" element={<PhotoCollage3D />} />
          <Route
            path="/collageDiversion"
            element={<PhotoCollage3DDiversion />}
          />
          <Route path="/collageCocina" element={<PhotoCollage3DCocina />} />
        </Routes>
        {showFooter && <Footer />}
      </Body>
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <ScrollToTop />
        <AppLayout />
      </Router>
    </ThemeProvider>
  );
}

export default App;
