import React, { Suspense, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// Importing images
import foto1 from "../../images/servicio1.jpeg";
import foto2 from "../../images/servicio2.jpeg";
import foto3 from "../../images/servicio3.jpeg";
import foto4 from "../../images/servicio4.jpeg";
import foto5 from "../../images/servicio5.jpeg";
import eventos from "../../images/eventos.png";
import javier from "../../images/javier.png";
import personas from "../../images/personas.png";
import calendarios from "../../images/calendario.png";
import calidad from "../../images/serviciosCalid.png";
import { Modal } from "@mui/material";
import alquilarIcon from "./../../images/alquilar.png";
import camareroIcon from "./../../images/camarero.png";
import posiciom from "./../../images/marcadorPosicion.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 80px 30px;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 66px 16px;
  }

  @media (max-width: 640px) {
    padding: 32px 16px;
  }
`;

/**hola */
const ContainerImagen = styled.div`
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
`;
const Title = styled.h1`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;
const TitleCard = styled.h1`
  font-size: 24px;
  color: black;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const SmallImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;
const ImageEventos = styled.img`
  width: 40%;
  height: 40%;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: 768px) {
    /* Ajusta el tamaño de pantalla según tus necesidades */
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  padding: 12px 24px;
  background-color: white;
  color: black;
  text-decoration: none;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #d1d1d1;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: #f0f0f0;
  }

  &:before {
    content: "•";
    margin-right: 8px;
  }
`;

/**nuevos */
const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 40px auto;
  font-family: Arial, sans-serif;
`;
const ListingDetails = styled.div`
  width: 65%;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%; /* Ocupa el ancho completo cuando la imagen se oculta */
    padding-right: 0; /* Opcional: elimina el padding derecho en pantallas pequeñas */
  }
`;
/**NUEVAS DE RESPONSIVE */
const PriceContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  gap: 20px;

  /* Media query para pantallas más pequeñas */
  @media (max-width: 768px) {
    flex-direction: column; /* Cambia la dirección a columna en pantallas pequeñas */
    gap: 10px; /* Reduce el espacio entre las secciones */
    width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
  }
`;

const PriceSection = styled.div`
  width: 40%;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    width: 100%; /* Hace que ocupe todo el ancho en pantallas pequeñas */
    padding: 15px; /* Ajusta el padding para pantallas pequeñas */
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  overflow-x: auto; /* Permite el desplazamiento horizontal en pantallas pequeñas */
  display: block; /* Hace que la tabla se comporte como un bloque para el desplazamiento horizontal */

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    font-size: 12px; /* Reduce el tamaño de la fuente */
    display: block; /* Asegura que la tabla tenga desplazamiento horizontal si es necesario */
  }
`;

const Td = styled.td`
  border: 1px solid #dddddd;
  padding: 8px;
  font-size: 14px;

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    font-size: 12px; /* Ajusta el tamaño de la fuente en pantallas pequeñas */
    white-space: nowrap; /* Evita que el contenido se ajuste en varias líneas */
  }
`;

const Th = styled.th`
  background-color: #f4ac1c;
  color: white;
  padding: 8px;
  font-size: 14px;

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    font-size: 12px; /* Ajusta el tamaño de la fuente en pantallas pequeñas */
    white-space: nowrap; /* Evita saltos de línea en las cabeceras */
  }
`;

const ReserveButton = styled.a`
  background-color: #ff385c;
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e3335f; /* Slightly darker shade for hover effect */
  }

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    font-size: 14px; /* Ajusta el tamaño de la fuente */
    padding: 8px 16px; /* Ajusta el padding */
  }
`;

const PriceSectionI = styled.div`
  width: 50%;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    width: 100%; /* Hace que ocupe todo el ancho en pantallas pequeñas */
  }
`;

const GuestSelector = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    flex-direction: column; /* Coloca los elementos en una columna en pantallas pequeñas */
    font-size: 12px; /* Ajusta el tamaño de la fuente */
  }
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 16px;

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    font-size: 14px; /* Ajusta el tamaño de la fuente */
    margin-top: 10px;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
  margin-top: 10px;
  text-align: center;

  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    font-size: 10px; /* Ajusta el tamaño de la fuente */
  }
`;

/***HASTA AQUI  */

const Subtitle = styled.p`
  color: white;
  margin-bottom: 20px;
`;

const Divider = styled.hr`
  color: white;
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
`;

const HostSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const HostImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

const HostInfo = styled.div`
  color: white;
  font-size: 14px;
`;

const FeatureSection = styled.div`
  margin-bottom: 20px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const FeatureIcon = styled.img`
  color: white;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const FeatureText = styled.p`
  font-size: 14px;
  color: white;
`;

const Description = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  color: white;
`;

const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ServiceList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Cuatro columnas */
  gap: 20px; /* Espacio entre los elementos */
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Tres columnas en pantallas medianas */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Dos columnas en pantallas más pequeñas */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Una columna en pantallas móviles */
  }
`;

const ServiceItem = styled.div`
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 13px; /* Tamaño más pequeño en pantallas medianas */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Tamaño más pequeño en móviles */
  }
`;

const ServiceIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
  color: white;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const ShowMoreButton = styled.button`
  padding: 12px 24px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DescriptionSection = styled.div`
  flex: 1;
  padding-right: 20px;

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    margin: 0; /* Elimina el margen para evitar desalineación */
  }

  ul {
    padding-left: 20px; /* Asegura que los puntos de la lista estén dentro del Card */
    margin: 0; /* Elimina el margen para evitar desbordamientos */
  }

  li {
    margin-bottom: 8px; /* Añade espacio entre elementos de la lista */
    word-break: break-word; /* Asegura que el texto largo se ajuste */
  }

  strong {
    display: block;
    font-size: 20px;
    margin-top: 16px;
    color: #f4ac1c;
  }
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex: 1;

  h2 {
    font-size: 24px;
    margin-left: 10px;
    color: #333;
  }
`;
const Img = styled.img`
  width: 10%;
  height: auto;
  max-width: 50px;
  max-height: 50px;

  @media (max-width: 640px) {
    max-width: 100px;
    max-height: 100px;
  }
`;
const ScheduleSection = styled.div`
  flex: 1;
  padding-left: 20px;

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    margin: 0; /* Elimina el margen para evitar desalineación */
  }

  ul {
    padding-left: 20px; /* Asegura que los puntos de la lista estén dentro del Card */
    margin: 0; /* Elimina el margen para evitar desbordamientos */
  }

  li {
    margin-bottom: 8px; /* Añade espacio entre elementos de la lista */
    word-break: break-word; /* Asegura que el texto largo se ajuste */
  }

  strong {
    display: block;
    font-size: 20px;
    margin-top: 16px;
    color: #f4ac1c;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  max-width: 60vw;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 12px;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 80vw; /* Ajusta el ancho para pantallas más pequeñas */
    padding: 15px; /* Reduce el padding en pantallas pequeñas */
  }

  @media (max-width: 480px) {
    max-width: 90vw; /* Aumenta el ancho para pantallas móviles */
    padding: 10px; /* Reduce más el padding para móviles */
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 1200px;
  max-height: calc(90vh - 40px);
  overflow-y: auto;
  margin: 20px auto;
  color: black;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 960px) {
    flex-direction: column; /* Cambia a columna para pantallas medianas */
    padding: 15px; /* Ajusta el padding */
  }

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    max-width: 100%; /* Se ajusta a todo el ancho de la pantalla en móviles */
    padding: 8px;
  }
`;
const Button = styled.a`
  display: inline-block;
  margin-top: 10px; /* Alinea el botón al final de la sección */
  padding: 10px 20px;
  background-color: #f4ac1c;
  color: #fff;
  text-decoration: none; /* Evita el subrayado */
  text-align: center;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: #d69317; /* Color de fondo al hacer hover */
  }
`;
// Estilos existentes

// Configuración del icono del marcador
const customIcon = new L.Icon({
  iconUrl: posiciom,
  iconSize: [50, 50],
  iconAnchor: [22, 38],
  popupAnchor: [-3, -76],
});

// Estilos para el contenedor del mapa
const MapWrapper = styled.div`
  width: 60%; /* Cambia a 100% para que ocupe el ancho completo */
  height: 600px; /* Altura fija, pero puedes ajustar según sea necesario */
  margin-bottom: 10px;

  /* Ajustes para dispositivos más pequeños */
  @media (max-width: 768px) {
    width: 100%;
    height: 300px; /* Altura ajustada para tablets */
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 300px; /* Altura ajustada para móviles */
  }
`;

function EasyPathSection() {
  const phoneNumber = "+593997924630";

  const handleClick = () => {
    console.log("Link clicked");
  };
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const position = [-0.35091822488942126, -78.44817238399911]; // Coordenadas del centro del mapa
  // Función para abrir Google Maps con la dirección
  const handleDirectionsClick = () => {
    const destination = `${position[0]},${position[1]}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${destination}`,
      "_blank"
    );
  };
  return (
    <Container>
      <ContainerImagen>
        <Title>CASA D´BRUNO - EVENTOS</Title>
        <GridContainer>
          <MainImage src={foto1} alt="Vista al mar" />
          <SmallImageGrid>
            <Image src={foto2} alt="Cocina 1" />
            <Image src={foto3} alt="Cocina 2" />
            <Image src={foto4} alt="Cocina 3" />
            <Image src={foto5} alt="Cocina 4" />
          </SmallImageGrid>
        </GridContainer>
        <ButtonContainer>
          <StyledLink to="/collage" onClick={handleClick}>
            Mostrar todas las fotos
          </StyledLink>
        </ButtonContainer>
      </ContainerImagen>
      <ContainerCard>
        <ListingDetails>
          <Title>Eventos en Casa D' Bruno:</Title>
          <Subtitle>
            Alquiler de espacios para la celebración de eventos, talleres,
            reuniones familiares y sociales, de forma exclusiva para su grupo de
            personas bajo la modalidad de reserva. El alquiler se puede dar bajo
            dos modalidades
          </Subtitle>
          <Divider />
          <HostSection>
            <HostImage src={javier} alt="Host" />
            <HostInfo>
              <p>Anfitrión: Javier Dominguez </p>
            </HostInfo>
          </HostSection>
          <FeatureSection>
            <Feature>
              <FeatureIcon src={calendarios} alt="Icon" />
              <FeatureText>Ideal para Eventos</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon src={personas} alt="Icon" />
              <FeatureText>Capacidad Hasta 100 personas</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon src={calidad} alt="Icon" />
              <FeatureText>
                Cuenta con dos tipos de servicio:
                <br /> <br /> 1. Alquiler solo de instalaciones. <br />
                <br /> 2. Alquiler todo incluido
              </FeatureText>
            </Feature>
          </FeatureSection>
          <Divider />
          {/**modal */}
          <ContainerModal>
            <Title>Instalaciones:</Title>
            <ServiceList>
              <ServiceItem>
                <ServiceIcon>🍳</ServiceIcon>
                Cocina, totalmente equipada.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>⚽</ServiceIcon>
                Cancha de césped natural indorfutbol / ecuavoley.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🎱</ServiceIcon>
                Mesa de billar.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🔥</ServiceIcon>
                Zona BBQ.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🌿</ServiceIcon>
                Jardín con césped sintético de 50 m2.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🎮</ServiceIcon>
                Futbolín.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🍕</ServiceIcon>
                Horno de leña.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🌳</ServiceIcon>
                Jardín con césped natural de 120 m2.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>📸</ServiceIcon>
                Zonas para fotos.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🏠</ServiceIcon>
                Salón cubierto para 20 personas.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🚻</ServiceIcon>
                Baños hombre / mujer.
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🅿️</ServiceIcon>
                Parqueadero interno seguro.
              </ServiceItem>
            </ServiceList>
            <ShowMoreButton onClick={openModal}>Ver los planes</ShowMoreButton>
            <Modal
              open={modalOpen}
              onClose={closeModal}
              contentLabel="Lista de Servicios"
              style={{
                content: {
                  top: "20%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "90%",
                  maxHeight: "90%",
                  overflow: "auto",
                  borderRadius: "12px",
                  padding: "20px",
                  backgroundColor: "white",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                },
              }}
            >
              <ModalContent>
                <Card>
                  <DescriptionSection>
                    <TitleSection>
                      <Img src={alquilarIcon} alt="Service Icon" />
                      <h2>Plan solo de instalaciones</h2>
                    </TitleSection>
                    <p>
                      En esta opción usted tendrá a disponibilidad exclusiva (un
                      solo grupo de persona) de todas las áreas de Casa d Bruno,
                      y correrá por su cuenta la organización de la hostelería.
                      <br />
                      <strong>Casa d´ Bruno </strong>
                      cuenta con las siguientes instalaciones:
                    </p>
                    <ul>
                      <li>Cocina, totalmente equipada.</li>
                      <li>Zona BBQ.</li>
                      <li>Horno de leña.</li>
                      <li>Salón cubierto para 20 personas.</li>
                      <li>Baños hombre / mujer</li>
                      <li>
                        Cancha de césped natural indorfutbol / ecuavoley 260 m2
                      </li>
                      <li>Mesa de billar</li>
                      <li>Futbolín</li>
                      <li>Hamacas</li>
                      <li>Zonas para fotos.</li>
                      <li>
                        Jardín con césped sintético de 50 m2 (puede ser cubierto
                        y utilizado como salón de eventos para 40 personas.)
                      </li>
                      <li>
                        Jardín con césped natural de 120 m2 (puede ser cubierto
                        y utilizado como salón de eventos para 60 personas.)
                      </li>
                    </ul>
                  </DescriptionSection>
                  <Divider />
                  <ScheduleSection>
                    <TitleSection>
                      <Img src={camareroIcon} alt="Service Icon" />
                      <h2>Plan todo incluido</h2>
                    </TitleSection>
                    <p>
                      En esta opción Casa d Bruno se encarga de todo para que su
                      evento sea un éxito y usted pueda disfrutarlo sin ninguna
                      preocupación.
                      <br />
                      <strong>Menú</strong>
                      Menús de 3 y 4 tiempos:
                    </p>
                    <ul>
                      <li>Vinos</li>
                      <li>Agua</li>
                      <li>Gaseosas</li>
                    </ul>
                    <strong>Menaje</strong>
                    <ul>
                      <li>Mesas</li>
                      <li>Sillas</li>
                      <li>Manteleria con colores a eleccion</li>
                      <li>Lazos</li>
                      <li>Servilletas</li>
                      <li>Forros de sillas</li>
                      <li>Cuberteria</li>
                      <li>Cristaleria</li>
                    </ul>
                    <strong>Personal de servicio</strong>
                    <ul>
                      <li>Cordinador de evento</li>
                      <li>Mesero 1 por cada 10 invitados</li>
                      <li>Bartender en barra</li>
                      <li>
                        Discomóvil e iluminación del evento con Dj profesional
                      </li>
                    </ul>
                    <strong>Decoracíon</strong>
                    <ul>
                      <li>Invitación digital</li>
                      <li>Centros de mesa</li>
                      <li>Decoración central</li>
                      <li>Porta pastel</li>
                      <li>Porta bocaditós</li>
                    </ul>
                    Todo esto según el plan.
                  </ScheduleSection>
                </Card>
                <ShowMoreButton onClick={closeModal}>Cerrar</ShowMoreButton>
              </ModalContent>
            </Modal>
          </ContainerModal>
        </ListingDetails>
        <ImageEventos src={eventos} alt="Cocina 1" />
      </ContainerCard>
      <Title> Precio y Planes de Bruno Eventos</Title>
      <PriceContainer>
        <PriceSectionI>
          <TitleCard>Plan solo de instalaciones</TitleCard>

          <GuestSelector>
            <span>125.00 $ un maximo de:</span>
            <span>20 personas</span>
          </GuestSelector>

          <Table>
            <thead>
              <Tr>
                <Th>HORARIOS</Th>
                <Th>VIERNES</Th>
                <Th>SABADO</Th>
                <Th>DOMINGO</Th>
                <Th>PRECIO</Th>
                <Th>N° PERSONAS</Th>
                <Th>PERSONA ADICIONAL</Th>
              </Tr>
            </thead>
            <tbody>
              <Tr>
                <Td>1</Td>
                <Td></Td>
                <Td>10 AM - 16 PM</Td>
                <Td>10 AM - 16 PM</Td>
                <Td>$125.00</Td>
                <Td>máx. 20 pax.</Td>
                <Td>$5.00</Td>
              </Tr>
              <Tr>
                <Td>2</Td>
                <Td>19 PM - 01 AM</Td>
                <Td>19 PM - 01 AM</Td>
                <Td></Td>
                <Td>$125.00</Td>
                <Td>máx. 20 pax.</Td>
                <Td>$5.00</Td>
              </Tr>
            </tbody>
          </Table>
          <ReserveButton
            href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
              "Hola, estoy interesado en obtener más información sobre los espacios disponibles en Casa D´ Bruno. Me interesa más sobre el Plan solo instalaciones. ¿Podrías brindarme más detalles?"
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Reservar
          </ReserveButton>
          <SmallText>Contactate con nosotros</SmallText>

          <TotalPrice>
            <span>Total</span>
            <span>125.00 $</span>
          </TotalPrice>
        </PriceSectionI>

        <PriceSection>
          <TitleCard>Plan todo incluido</TitleCard>

          <Table>
            <thead>
              <Tr>
                <Th>PLANES</Th>
                <Th>Precio por invitado</Th>
                <Th>N° invitados</Th>
              </Tr>
            </thead>
            <tbody>
              <Tr>
                <Td>Cumpleaños infantiles</Td>
                <Td>
                  Desde: $10.00 <br /> hasta: $30.00
                </Td>
                <Td>min 20 pax.</Td>
              </Tr>
              <Tr>
                <Td>Eventos (boda, bautizo, cumpleaños, grados, etc.)</Td>
                <Td>
                  Desde: $15.00 <br /> hasta: $45.00
                </Td>
                <Td>min 20 pax.</Td>
              </Tr>
            </tbody>
          </Table>

          <ReserveButton
            href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
              "Hola, estoy interesado en obtener más información sobre los espacios disponibles en Casa D´ Bruno. Me interesa más sobre el Plan todo Incluido. ¿Podrías brindarme más detalles?"
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Reservar
          </ReserveButton>
          <SmallText>Contactate con nosotros</SmallText>

          <TotalPrice>
            <span>Total</span>
            <span>No disponible</span>
          </TotalPrice>
        </PriceSection>
      </PriceContainer>
      {/* Agregar el mapa */}
      <Title>Nuestra ubicacion Casa D' Bruno</Title>
      <MapWrapper>
        <MapContainer
          center={position}
          zoom={15}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={customIcon}>
            <Popup>
              Aquí es donde te quedarás.
              <br />
            </Popup>
          </Marker>
        </MapContainer>
      </MapWrapper>
      <Button onClick={handleDirectionsClick}>Cómo llegar</Button>
    </Container>
  );
}

export default EasyPathSection;
