import React from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import styled from "styled-components";
import { experiences } from "../../data/constants";
import ExperienceCard from "../cards/ExperienceCard";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  headContainerAnimation,
  headContentAnimation,
  headTextAnimation,
} from "../../utils/motion";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-contnet: center;
  margin-top: 50px;
  position: rlative;
  z-index: 1;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const TitleContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 52px;
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const FirstHalf = styled.span`
  color: #aa1c16;
`;

const SecondHalf = styled.span`
  color: #f4ac1c;
`;
const Desc = styled.div`
  font-size: 18px;
  text-align: justify;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    font-size: 17px;
  }

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 30px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;
const SubTitle = styled.div`
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
  margin-bottom: 42px;
  color: ${({ theme }) => theme.text_primary + 95};
  max-width: 800px; /* Limita el ancho del texto */
  padding: 0 20px; /* Espacio alrededor para evitar que quede pegado a los bordes */

  @media (max-width: 960px) {
    text-align: center; /* Solo en pantallas pequeñas */
    font-size: 16px;
    line-height: 28px;
    padding: 0 15px; /* Ajusta el padding en pantallas pequeñas */
  }
`;

const Experience = () => {
  return (
    <Container id="Experience">
      <Wrapper>
        <TitleContainer>
          <FirstHalf>CASA D´</FirstHalf>
          <SecondHalf> BRUNO</SecondHalf>
        </TitleContainer>
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </Wrapper>
    </Container>
  );
};

export default Experience;
