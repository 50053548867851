import * as THREE from "three";
import { useRef, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image, ScrollControls, Scroll, useScroll } from "@react-three/drei";
import { proxy, useSnapshot } from "valtio";
import { easing } from "maath";
import styled from "styled-components";
import logo from "../../../images/Logo.png";

// Agregar estilos
const CanvasContainer = styled.div`
  width: 100%;
  height: 94vh;
  overflow: hidden;
  position: relative;
`;
const AStyle = styled.a`
  pointer-events: all;
  color: #ffffff; /* Corrected to a valid color value */
  text-decoration: none;
`;

const material = new THREE.LineBasicMaterial({ color: "white" });
const geometry = new THREE.BufferGeometry().setFromPoints([
  new THREE.Vector3(0, -0.5, 0),
  new THREE.Vector3(0, 0.5, 0),
]);
// Cargar todas las imágenes de la carpeta 'DIVERSION' dentro del directorio 'public'
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("/public/SERVICIOS", false, /\.(jpeg)$/)
);

// Crear el estado con las URLs de las imágenes
const state = proxy({
  clicked: null,
  urls: images,
});

function Minimap() {
  const ref = useRef();
  const scroll = useScroll();
  const { urls } = useSnapshot(state);
  const { height } = useThree((state) => state.viewport);
  useFrame((state, delta) => {
    ref.current.children.forEach((child, index) => {
      const y = scroll.curve(
        index / urls.length - 1.5 / urls.length,
        4 / urls.length
      );
      easing.damp(child.scale, "y", 0.15 + y / 6, 0.15, delta);
    });
  });
  return (
    <group ref={ref}>
      {urls.map((_, i) => (
        <line
          key={i}
          geometry={geometry}
          material={material}
          position={[i * 0.06 - urls.length * 0.03, -height / 2 + 0.6, 0]}
        />
      ))}
    </group>
  );
}

function Item({ index, position, scale, c = new THREE.Color(), ...props }) {
  const ref = useRef();
  const scroll = useScroll();
  const { clicked, urls } = useSnapshot(state);
  const [hovered, hover] = useState(false);
  const click = () => (state.clicked = index === clicked ? null : index);
  const over = () => hover(true);
  const out = () => hover(false);
  useFrame((state, delta) => {
    const y = scroll.curve(
      index / urls.length - 1.5 / urls.length,
      4 / urls.length
    );
    easing.damp3(
      ref.current.scale,
      [clicked === index ? 3 : scale[0], clicked === index ? 5 : 4 + y, 1],
      0.15,
      delta
    );
    ref.current.material.scale[0] = ref.current.scale.x;
    ref.current.material.scale[1] = ref.current.scale.y;
    if (clicked !== null && index < clicked)
      easing.damp(ref.current.position, "x", position[0] - 0.5, 0.15, delta);
    if (clicked !== null && index > clicked)
      easing.damp(ref.current.position, "x", position[0] + 0.5, 0.15, delta);
    if (clicked === null || clicked === index)
      easing.damp(ref.current.position, "x", position[0], 0.15, delta);
    easing.damp(
      ref.current.material,
      "grayscale",
      hovered || clicked === index ? 0 : Math.max(0, 1 - y),
      0.1,
      delta
    );
    easing.dampC(
      ref.current.material.color,
      hovered || clicked === index ? "white" : "#aaa",
      hovered ? 0.3 : 0.15,
      delta
    );
  });
  return (
    <Image
      ref={ref}
      {...props}
      position={position}
      scale={scale}
      onClick={click}
      onPointerOver={over}
      onPointerOut={out}
    />
  );
}
function Items({ w = 2.7, gap = 0.15 }) {
  const { urls } = useSnapshot(state);
  const { width } = useThree((state) => state.viewport);
  const xW = w + gap;
  return (
    <ScrollControls
      horizontal
      damping={0.1}
      pages={(width - xW + urls.length * xW) / width}
    >
      <Minimap />
      <Scroll>
        {urls.map((url, i) => (
          <Item
            key={i}
            index={i}
            position={[i * xW, 0, 0]}
            scale={[w, 4, 1]}
            url={url}
          />
        ))}
      </Scroll>
    </ScrollControls>
  );
}
// Estilo para el BackLink con media queries para hacerlo responsive
const BackLink = styled(Link)`
  color: white;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ff385c; /* Color al pasar el ratón */
  }

  @media (max-width: 767.98px) {
    font-size: 10px; /* Tamaño de fuente más pequeño en pantallas pequeñas */
  }
`;
function Overlay() {
  const currentDate = new Date().toLocaleDateString();
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
        width: "100%",
        height: "100%",
      }}
    >
      <AStyle
        href="https://pmnd.rs/"
        style={{
          position: "absolute",
          bottom: 40,
          left: 60,
          fontSize: "10px",
          color: "white",
        }}
      >
        CASA D´BRUNO
        <br />
        COLLAGE EVENTOS
      </AStyle>
      <div
        style={{
          position: "absolute",
          top: 40,
          left: 1800,
          fontSize: "13px",
          color: "white",
        }}
      >
        CASA D´BRUNO —
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 40,
          right: 40,
          fontSize: "13px",
          color: "white",
        }}
      >
        {currentDate}
      </div>
      <BackLink
        to="/servicios"
        style={{
          position: "absolute",
          top: 40,
          left: 40,
          pointerEvents: "auto", // Habilita los clics
          zIndex: 1000, // Asegura que el enlace esté en la parte superior de otros elementos
        }}
      >
        ⬅️ CASA D´BRUNO — REGRESAR A EVENTOS{" "}
      </BackLink>
    </div>
  );
}

export const PhotoCollage3D = () => (
  <CanvasContainer>
    <Canvas
      gl={{ antialias: true }}
      dpr={[2, 60]} // Mejora la densidad de píxeles
      onPointerMissed={() => (state.clicked = null)}
    >
      <Items />
    </Canvas>
    <Overlay />
    <img
      src={logo}
      style={{ position: "absolute", bottom: 50, left: 20, width: 30 }}
      alt="Logo"
    />
  </CanvasContainer>
);

export default PhotoCollage3D;
