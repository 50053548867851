import React from "react";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Bio } from "../../data/constants";
import { FacebookRounded, Instagram, Twitter } from "@mui/icons-material";
import kevin from "../../images/logoKevin.png";
import { FaTiktok } from "react-icons/fa";

// Styled Components
const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  position: relative;
`;

const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.primary};
`;

const Nav = styled.ul`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled(LinkR)`
  color: ${({ theme }) => theme.text_primary};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

const PoweredBy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
`;

const PoweredByLogo = styled.img`
  height: 100px; /* Adjust size as needed */
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Logo>CASA D´BRUNO</Logo>
        <Nav>
          <NavLink to="/servicios">Eventos</NavLink>
          <NavLink to="/cocina">Cocina</NavLink>
          <NavLink to="/diversion">Diversión</NavLink>
          <NavLink to="/aliados">Aliados</NavLink>
        </Nav>
        <SocialMediaIcons>
          <SocialMediaIcon href={Bio.facebook} target="_blank">
            <FacebookRounded />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.twitter} target="_blank">
            <FaTiktok />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.insta} target="_blank">
            <Instagram />
          </SocialMediaIcon>
        </SocialMediaIcons>
        <Copyright>
          &copy; 2024 CASA D´BRUNO. Todos los derechos reservados.
        </Copyright>
        <PoweredBy>
          <span>Powered By</span>
          <a
            href="https://api.whatsapp.com/send?phone=+593992588721&text=Hola,%20necesito%20información%20"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PoweredByLogo src={kevin} alt="Powered by logo" />
          </a>
        </PoweredBy>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
